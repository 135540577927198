import FirebaseClient from './firebase'
import { getMessaging, getToken } from "firebase/messaging";

const Service = {
    messaging: undefined,
    init() {
        this.messaging = getMessaging();
        // this.messaging.useServiceWorker(`${process.env.BASE_URL}service-worker.js`);
    },
    getToken() {
        return getToken(this.messaging, { vapidKey: 'BKp-YhaS8qw0GQl33xkY-GtoR8hPXCLsmXzOqdL16XZd2TmFkdcFtTDbIM8rAorjiID5rBJB5zY8kmcV3JeleOQ' }).then((currentToken) => {
            if (currentToken) {
                console.log('current token', currentToken);
            } else {
                console.log('No registration token available. Request permission to generate one.');
            }
        });
    }
};

export default Service;