import {initializeApp} from 'firebase/app';
import {getFirestore, connectFirestoreEmulator} from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyDlgvVgFwQZwvh5XaWIQgPX-VyAAlkNSAA",
    authDomain: "am-events-21a27.firebaseapp.com",
    projectId: "am-events-21a27",
    storageBucket: "am-events-21a27.appspot.com",
    messagingSenderId: "790710795627",
    appId: "1:790710795627:web:a2badfcaf7913ad121a02e",
    measurementId: "G-C9WZ8254CK"
};

const Service = {
    app: undefined,
    init() {
        this.app = initializeApp(firebaseConfig);
    },
    getFirestore() {
        const db = getFirestore(this.app);
        if (window.location.hostname === 'localhost') {
            console.log('connecting locally');
            connectFirestoreEmulator(db, 'localhost', '8080');
        }
        return db;
    }
};

export default Service;