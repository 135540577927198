import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeIndex from '../views/home/index'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeIndex
    },
    {
        path: '/events',
        name: 'events',
        component: () => import('../views/events/index')
    },
    {
        path: '/events/:eventId',
        name: 'events',
        component: () => import('../views/events/view')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
