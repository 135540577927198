<template>
  <div class="component">
    <h1>Home View</h1>
    <router-link to="events">Events</router-link>
  </div>
</template>

<script type="text/babel">
export default {
  name: "views-home-index",
  watch: {},
  props: {},
  data() {
    return {};
  },
  methods: {},
  computed: {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  mixins: [],
  components: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
