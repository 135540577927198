<template>
  <div id="app" data-app>
    <user-bar />
    <router-view/>
  </div>
</template>

<script type="text/babel">
import FirestoreClient from "@/clients/firestore";
import {mapGetters} from 'vuex'
import UserBar from '@/components/user-bar'

export default {
  name: "views-app",
  watch: {},
  props: {},
  data() {
    return {
      userSnapshotListener: undefined
    };
  },
  methods: {
    setEventsSnapshotListener() {
      const reference = FirestoreClient.user('1234');
      this.userSnapshotListener = FirestoreClient.snapshotListener(reference, this.onUserSnapshot)
    },
    onUserSnapshot(doc) {
      const user = doc.data();
      this.$store.commit('user', user);
    }
  },
  computed: {
    ...mapGetters([
        'user'
    ])
  },
  created() {
    this.setEventsSnapshotListener();
  },
  mounted() {
  },
  beforeDestroy() {
    if (this.userSnapshotListener) {
      this.userSnapshotListener();
    }
  },
  mixins: [],
  components: {
    UserBar
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
