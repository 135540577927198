<template>
  <div class="component">
    <v-btn v-on:click="setMessagingToken">setMessagingToken</v-btn>
  </div>
</template>

<script type="text/babel">
import MessagingService from '@/clients/messaging';
import FirestoreService from '@/clients/firestore';
import {arrayUnion} from 'firebase/firestore';

export default {
  name: "components-user_bar-index",
  watch: {},
  props: {},
  data() {
    return {};
  },
  methods: {
    setMessagingToken() {
      MessagingService.getToken()
          .then((token) => {
            console.log('token', token);
            return FirestoreService.user("1234")
                .update({
                  messaging_tokens: arrayUnion(token)
                });
          })
          .then((result) => {
            console.log(result);
          })
          .catch((e) => {
            console.log(e);
          })
    }
  },
  computed: {},
  created() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  mixins: [],
  components: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
