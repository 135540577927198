import FirebaseClient from './firebase'
import {collection, doc, deleteDoc, onSnapshot} from 'firebase/firestore';

const Service = {
    db: undefined,
    init() {
        this.db = FirebaseClient.getFirestore();
    },
    events(userId) {
        return collection(this.db, 'users', userId, 'events');
    },
    user(userId) {
        return doc(this.db, `users/${userId}`);
    },
    remove(reference) {
        return deleteDoc(reference);
    },
    snapshotListener(collection, callback) {
        return onSnapshot(collection, callback);
    },
    getCollection(collectionName) {
        return collection(this.db, collectionName);
    }
};

export default Service;